import type { QueryHookOptions, QueryOptions } from '@apollo/client';
// function withDraftToken<T extends QueryOptions>(
//   options: T,
//   maybeToken?: string,
// ): T {
//   if (!draftMode().isEnabled) return options;

//   const { GRAPHQL_DRAFT_TOKEN_HEADER } = getServerConstants();

//   const token = maybeToken ?? cookies().get(GRAPHQL_DRAFT_TOKEN_HEADER)?.value;

//   if (!token) return options;

//   return {
//     ...options,
//     context: {
//       ...options.context,
//       headers: {
//         ...options.context?.headers,
//         'X-Craft-Token': token,
//       },
//     },
//   };
// }

export default function createNextCacheTags<T extends QueryOptions | QueryHookOptions>(
  options: T,
  tags: string[] = []
): T {
  const { context, variables = {} } = options || {};
  const { fetchOptions, headers } = context || {};
  const { next } = fetchOptions || {};
  const { tags: currentTags = [] } = next || {};

  const tagFields = ['entryId', 'uri', 'section', 'type', 'group'];
  // const tagFields = [];

  // const query = 'query' in options ? options.query : undefined;

  // const queryName = query?.name?.value;

  // if (queryName) {
  //   tagFields.push(queryName);
  // }

  // if ('uri' in variables) {
  //   // console.log('query.__meta__', (query as any)?.__meta__);

  //   if (tags.includes('draftToken')) {
  //     console.log('Draft mode is enabled');
  //   }
  // }
  const newTags = tagFields.reduce((acc, field) => {
    if (field in variables) {
      acc.push(`${field}:${variables[field]}`);
    }
    return acc;
  }, tags);

  // if (newTags.length === 0) {
  //   console.warn('No tags were added to the query. This may result in the query not being cached.');
  //   console.warn('Query:', options);
  // }

  return {
    ...options,
    context: {
      ...context,
      fetchOptions: {
        ...fetchOptions,
        next: {
          ...next,
          tags: [...currentTags, ...newTags, 'next-cache'],
        },
        // method: 'GET',
      },
    },
  };
}
